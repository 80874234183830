<template>
  <div class="empty-tips u-flex-col u-col-center u-row-center">
    <van-image
      width="2.6rem"
      height="2.7rem"
      :src="require('../assets/images/empty_bg.png')"
    />
    <p class="empty-title">{{ text }}</p>
    <slot />
  </div>
</template>
<script>
export default {
  name: "EmptyTip",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-tips {
  height: 100%;
}
.empty-title {
  font-size: 0.48rem;
  font-weight: 700;
  color: #515151;
  margin: 0.48rem 0 0.24rem;
}
</style>
