<template>
  <div>
    <ShoppingHeader
      style="background: #fbf7f3"
      :title="$t('route.shoppingHome')"
    />
    <div class="container">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in goodsBannerList" :key="item.id">
          <router-link :to="`/shoppingGoodsDetail/${item.goodsId}`"
            ><img :src="item.imgUrl" style="width: 100%"
          /></router-link>
        </van-swipe-item>
      </van-swipe>
      <div style="font-size: 0.3rem; margin-top: 0.36rem; color: #333">
        {{ $t("fengShuiGoods") }}
      </div>
      <div v-if="isFinish">
        <div v-if="goodsData.list.length">
          <router-link
            :to="`/shoppingGoodsDetail/${item.id}`"
            v-for="(item, index) in goodsData.list"
            :key="index"
            style="display: flex; margin-top: 0.36rem"
            @click="() => {}"
          >
            <img :src="item.goodsImg" style="width: 100px; height: 100px" />
            <div
              style="
                margin-left: 0.36rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <div>
                <h4 style="font-size: 0.32rem; color: #a1844c">
                  {{ item.goodsName }}
                </h4>
                <div style="font-size: 0.28rem; color: #666; margin-top: 8px">
                  {{
                    item.goodsDescription.length > 32
                      ? item.goodsDescription.slice(0, 32) + "..."
                      : item.goodsDescription
                  }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  font-size: 0.32rem;
                  color: #333;
                "
              >
                <span>HK${{ item.price }}</span
                ><span
                  v-if="item.stock === 0"
                  style="
                    background: #ee0a24;
                    color: #fff;
                    border-radius: 4px;
                    padding: 1px 4px;
                    font-size: 8px;
                  "
                  >{{ $t("soldOut") }}</span
                >
              </div>
            </div>
          </router-link>
        </div>

        <EmptyTip v-else>
          <span class="empty-tip"></span>
        </EmptyTip>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyTip from "@/components/EmptyTip";
import ShoppingHeader from "@/components/ShoppingHeader";

import { getGoodsBannerList, getGoodsList } from "@/api/goods";

export default {
  components: {
    EmptyTip,
    ShoppingHeader,
  },
  data() {
    return {
      // 地址列表是否加载完成
      isFinish: false,
      loading: true,
      goodsBannerList: [],
      goodsParams: {
        pageNum: 0,
        pageSize: 20,
      },
      goodsData: {
        total: 0,
        pages: 0,
        list: [],
      },
    };
  },
  created() {
    this.getGoodsBannerList();
    this.getGoodsData();
  },
  methods: {
    getGoodsData() {
      this.goodsParams.pageNum++;
      getGoodsList(this.goodsParams).then((res) => {
        this.loading = false;
        if (res.code !== 200) return;
        this.goodsData = {
          total: res.data.total,
          pages: res.data.pages,
          list: res.data.list,
        };
      });
    },
    getGoodsBannerList() {
      getGoodsBannerList().then((res) => {
        this.isFinish = true;
        if (res.code !== 200) return;
        this.goodsBannerList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #fbf7f3;
  padding: 0.4rem 0.4rem 1.6rem;
}

.address-list {
  font-size: 0.28rem;
  font-weight: 400;
  text-align: justify;
  color: #515151;
  padding: 0.3rem 0.24rem 0.24rem;
  background: #fefdfc;
  border-radius: 0.08rem;
  margin-bottom: 0.4rem;
}

.user-name {
  font-size: 0.32rem;
  font-weight: 600;
  margin-right: 0.12rem;
}

.active {
  display: block;
  background: #c69636;
  border-radius: 2rem 0 0 2rem;
  font-size: 0.24rem;
  color: #ffffff;
  padding: 0.02rem 0.16rem 0.02rem 0.24rem;
}

.address-info {
  word-break: break-all;
  margin-top: 0.15rem;
}

.submit-btn {
  height: 1.6rem;
  padding: 0 0.48rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fbf7f3;
}

.slot-tips {
  line-height: 1.2rem;
  font-size: 0.28rem;
  font-weight: 400;
  text-align: justify;
  color: #afafaf;
  text-align: center;
  margin: -0.03rem 0;
}

.add-address {
  padding-top: 0.4rem;

  .van-cell {
    padding: 0.36rem 0.4rem;
  }

  /deep/ {
    .van-cell::after {
      display: none;
    }

    .van-field__label {
      font-size: 0.28rem;
      font-weight: 500;
      text-align: justify;
      color: #515151;
    }

    .van-field__error-message {
      height: 0;
      color: #d1b06f;
    }

    .van-field--error .van-field__control,
    .van-field--error .van-field__control::placeholder {
      color: #d1b06f;
    }
  }
}

.address-title {
  font-size: 0.32rem;
  font-weight: 600;
  color: #755a24;
  padding: 0 0.4rem 0.2rem;
  border-bottom: 0.04rem solid rgba(151, 151, 151, 0.12);
  margin-bottom: 0.18rem;
}

.empty-tip {
  opacity: 0.6;
  font-size: 0.28rem;
  color: rgba(81, 81, 81, 0.6);
}

/deep/ {
  .van-swipe-cell__right {
    width: 0.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2644d;
  }

  .van-popup__close-icon--top-right {
    font-size: 0.4rem;
    color: #515151;
  }

  .van-field__right-icon {
    padding: 0;
  }

  .van-icon-arrow {
    color: #000000;
    font-size: 0.25rem;
  }
}
</style>
